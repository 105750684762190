.success-screen {
    text-align: center;
    padding: 50px;
    border-radius: 5px;
    background-color: #dff0d8;
    color: #3c763d;
    width: 80%;
    margin: 100px auto;

  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, -10%, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .animate-fade-in {
    animation: fadeIn 1s ease-in;
  }
  