* {
  font-family: Arial, Helvetica, sans-serif;
}

/* General container styles for centering and presentation */
.container_react {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

a:link{
  text-decoration: none;
}

/* Styling for form and its positioning */
.form_react,
.form-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

/* For horizontal row items within the form */
.form-row_react {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

/* Individual item styling, such as cards or inputs */
.item_react {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 200px;
  background: #fff;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Image styling within items */
.img_react {
  max-width: 100%;
  max-height: 100px;
  margin-bottom: 10px;
}

/* Item hover effect for interactivity */
.item_react:hover {
  transform: translateY(-5px);
}

/* Button styles for main actions */
/* .button_react, */
.btn-main.btn-color {
  display: block !important;
  font: 600 16px/1 "Manrope", sans-serif !important;
  color: #072e56;
  border: 1px solid #072e56;
  border-radius: 50px;
  padding: 11px 32px;
  max-width: max-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 10px;
}

/* Main button hover effect */
.btn-main:hover,
.btn-main.btn-color:hover {
  color: #ffffff !important;
  background-color: #4392C7 !important;
  border-color: #4392C7 !important;
}

/* Color variations for main button */
.btn-main.btn-color {
  color: #ffffff;
  background-color: #4392c7;
  border: 1px solid #4392c7;
  margin: 0 auto!important;
  margin-top: 15px;
}

/* Button styles for secondary actions */
.btn-back,
.btn-secondary {
  color: #757575!important; /* A soft gray for text */
  font-weight: normal; /* Less emphasis on font weight */
  background-color: transparent; /* No background for a minimalistic look */
  border: none; /* Remove the border */
  border-color: #757575;
  padding: 5px 10px; /* Reduce padding for a slimmer look */
  font-size: 0.875rem; /* Smaller font size for less visual weight */
  border-radius: 5px; /* Retain the rounded corners but could be less */
  cursor: pointer; /* Keep the pointer to indicate clickability */
  transition: color 0.3s ease; /* Transition on color change for interactivity */
  width: auto; /* Adjust width to content size for a less blocky appearance */
  margin-top: 10px; /* Less space above the button */
  margin-bottom: 10px; /* Less space below the button */
  display: flex;
}

.btn-back:hover,
.btn-secondary:hover {
  color: #303030; /* Slightly darker gray to indicate hover state */
  background-color: transparent; /* Maintain transparent background on hover */
}

/* Button group styling for arranging multiple buttons */
.button_group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* Individual button styles within button groups */
.button_group button {
  margin: 10px 0;
  border: none;
  background-color: #4392C7; /* Your button color */
  color: white;
  cursor: pointer;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 24px;
}



/* Form wrapper styles for alignment and spacing */
.form-wrap form {
  gap: 24px;
  max-width: 615px;
  padding: 32px 40px;
  border-radius: 24px;
  border: 1px solid #072e56;
  width: 100%;
}

.form-group {
  padding: 7px 0px;
}

.form-control::placeholder {
 
 color: #cbcbcb!important;

}

.´privacy {
  margin-top: 1rem;
}
.privacy-policy-label {
  display: inline;
  margin-left: 1rem;
}

#questionInput {
  height: 80px;
}

@media screen and (max-width: 768px) {
  .form-wrap {
    width: 94%;
    margin: 0 auto;
  }
}
